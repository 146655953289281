import React from 'react';
import {APIProvider, Map, AdvancedMarker, MapCameraChangedEvent, Pin, InfoWindow} from '@vis.gl/react-google-maps';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 41.923667083414465,
  lng: 8.729277049198213
};

const info = {
  lat: 41.96808871517879,
  lng: 8.63679153415967
}

type Poi ={ key: string, location: google.maps.LatLngLiteral }
const locations: Poi[] = [
    {key: 'saliccia', location: { lat: 41.96808871517879, lng: 8.63679153415967  }},
];

const Googlemap = () => {
  const [mapCenter, setMapCenter] = React.useState(center);
  const [mapZoom, setMapZoom] = React.useState(11);

  return (
    <APIProvider apiKey="AIzaSyBB7MyXGdSpExnHtVwkdRk4cY0YHf5ANVw" onLoad={() => console.log('Maps API has loaded.')}>
      <Map 
        zoom={mapZoom}
        center={mapCenter} 
        style={containerStyle}
        mapId='f06a6183e6f3e6e9'
        onCameraChanged={ (ev: MapCameraChangedEvent) => {
          console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom);
          setMapCenter(ev.detail.center);
          setMapZoom(ev.detail.zoom);        
        }}>
        <PoiMarkers pois={locations} />
        <InfoWindow position={info}>
          <p>
            Lieu du Mariage: Domaine de Sallicia
          </p>
        </InfoWindow>
      </Map>
    </APIProvider>
  );
};

const PoiMarkers = (props: {pois: Poi[]}) => {
  return (
    <>
      {props.pois.map( (poi: Poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}>
        <Pin />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default Googlemap;